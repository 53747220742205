import { toast } from "vue3-toastify";

const configToast = (type) => {

  const commonConfig = {
    toastClassName: "custom-toast",
    autoClose: 3000,
  };
  const arrType = {
    success: {
      toastClassName: "toast toast-success", // Classe de sucesso
      bodyClassName: "toast-body", // Corpo do toast
      progressClassName: "toast-progress-bar", // Barra de progresso
    },
    error: {
      toastClassName: "toast toast-error", // Classe de erro
      bodyClassName: "toast-body", // Corpo do toast
      //   progressClassName: "toast-progress-bar-error",
      progressClassName: "fancy-progress-bar222",
    },
    warning: {},
  };
  console.log(arrType);
  return { ...arrType[type], ...commonConfig };
};

export const toaster = (msg, type = null) => {
  let toastType = toast;
  if (
    type == "success" ||
    type == "error" ||
    type == "info" ||
    type == "warning"
  ) {
    toastType = toastType[type];
  } else {
    toastType = toast;
  }
  //   switch (type) {
  //     case "success":
  //       toastType = toast[type];
  //       break;
  //     case "error":
  //       toastType = toast[type];
  //       break;
  //     case "info":
  //       toastType = toast[type];
  //       break;
  //     case "warning":
  //       toastType = toast[type];
  //       break;
  //     default:
  //       let toastType = toast;
  //       break;
  //   }
  //   const toastType = toast[type] ? toast[type] : toast;
  return toastType(msg, {
    ...configToast(type),
  });
};
