<template>
  <div style="height: 300px;" class="chart-content">
    <Line :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
      :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height"
      style="width: 100%; height: 300px; padding: 20px;" />

  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, PointElement, Legend, LineElement, CategoryScale, LinearScale, Filler } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale, Filler);

export default {
  name: 'BarChart',
  components: { Line },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      // default: 500
    },
    height: {
      type: Number,
      default: 100
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      chartData: {
        labels: ["January", "February", "March", "April", "May", "June"],
        datasets: [
          {
            label: "My Dataset",
            backgroundColor: "rgba(75,192,192,0.3)",
            borderColor: "rgba(75,192,192,1)",
            data: [65, 59, 80, 81, 56, 55],
            fill: true,
            tension: 0.4,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Line Chart Example",
          },
        },
      },
    }
  }

}
</script>

<style>
.chart-content{
  background-color: #212733;
  padding: 0px;
  border-radius: 20px;
  
}
</style>