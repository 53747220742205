<template>
    <div class="rn-notification-area right-fix-notice">
        <div class="h--100">
            <div class="notice-heading">
                <h4>Notificação</h4>
                <nice-select
                    :options="[
                        {value: 'newest', text: 'Newest'},
                        {value: 'trending', text: 'Tranding'},
                        {value: 'saved', text: 'Saved'},
                        {value: 'deleted', text: 'Delated'}
                    ]"
                    :default-current="0"
                    placeholder="Sort by likes"
                    name="like"
                />
            </div>
        </div>
        <div class="rn-notification-wrapper">
            <div class="single-notice"
                 v-for="(notification, index) in notificationList"
                 :key="`notification-${index}`">
                <div class="thumbnail">
                    <router-link :to="notification.url">
                        <img :src="notification.img" alt="Images">
                    </router-link>
                </div>
                <div class="content-wrapper">
                    <router-link :to="notification.url">
                        <h6 class="title">{{ notification.title }}</h6>
                    </router-link>
                    <p>{{ notification.description }}</p>
                    <div class="notice-time">
                        <span>{{ notification.date }} </span>
                        <span>{{ notification.time }}</span>
                    </div>
                    <router-link :to="notification.url" class="btn btn-primary-alta">{{ notification.urlText }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NiceSelect from '@/components/select/NiceSelect'

    export default {
        name: 'NotificationListSidebar',
        components: {NiceSelect},
        data() {
            return {
                notificationList: [
                    {
                        url: '/',
                        urlText:"Começar Agora",
                        img: require(`@/assets/images/icons/meta.svg`),
                        title: ' Parabéns!',
                        description: 'Sua conta foi aprovada na Moderna Pro. Comece agora a mostrar todo o seu potencial.',
                        date: '15 de Julho',
                        time: '22:32',
                       
                    },
                    {
                        url: '/',
                        urlText:"Ver Detalhes",
                        img: require(`@/assets/images/icons/meta.svg`),
                        title: 'Resultado liberado!',
                        description: 'Você alcançou sua meta de desempenho. Capital adicional foi liberado para a sua conta.',
                        date: '15 de Julho',
                        time: '22:32'
                    },
                    {
                        url: '/',
                        urlText:"Conferir",
                        img: require(`@/assets/images/icons/meta.svg`),
                        title: 'Operação bem-sucedida!',
                        description: 'Você acabou de lucrar 2,5% na sua última operação. Continue assim!',
                         date: '10 de Julho',
                        time: '12:32'
                    },
                    {
                        url: '/',
                        urlText:"Finalizar Agora",
                        img: require(`@/assets/images/icons/meta.svg`),
                        title: 'Próximo passo!',
                        description: 'Finalize sua avaliação para desbloquear o acesso completo à Moderna Pro.',
                        date: '11 de Julho',
                        time: '12:32'
                    },
                    
                ],
            }
        }
    }
</script>