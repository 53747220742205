import axios from "axios";
import env from "../config/env";
import { useLoadingStore } from "../stores/loadingStore"; // Importa o Pinia store

class API {
  constructor(baseurl = "auth", authentication = false) {
    this.httpClient = axios.create({
      timeout: 5000, // Timeout global
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.setBaseURL(baseurl);
    this.setupInterceptors(authentication);
  }

  // Método para alterar a baseURL
  setBaseURL(service) {
    const serviceUrls = {
      auth: env.api.baseUrlAuth,
      business: env.api.baseUrlBusiness,
    };

    if (serviceUrls[service]) {
      this.httpClient.defaults.baseURL = serviceUrls[service];
    } else {
      throw new Error("Microserviço não encontrado");
    }
  }

  setupInterceptors(authentication) {
    if (authentication) {
      const { token } = JSON.parse(localStorage.getItem("authToken"));

      if (token)
        this.httpClient.interceptors.request.use((config) => {
          config.headers["Authorization"] = `Bearer ${token}`;
          return config;
        });
    }
    // Interceptor de requisição
    this.httpClient.interceptors.request.use(
      (config) => {
        const loadingStore = useLoadingStore(); // Obtém o store de loading do Pinia
        // Se `disableLoading` não estiver presente ou for `false`, ativa o loading
        if (!config.disableLoading) {
          loadingStore.startLoading();
        }
        return config;
      },
      (error) => {
        const loadingStore = useLoadingStore();
        loadingStore.stopLoading(); // Garante que o loading é desativado em caso de erro
        return Promise.reject(error);
      }
    );

    // Interceptor de resposta
    this.httpClient.interceptors.response.use(
      (response) => {
        // Se `disableLoading` não estiver presente ou for `false`, desativa o loading
        if (!response.config.disableLoading) {
          const loadingStore = useLoadingStore();
          loadingStore.stopLoading();
        }
        return response;
      },
      (error) => {
        const loadingStore = useLoadingStore();
        loadingStore.stopLoading(); // Garante que o loading é desativado em caso de erro
        return Promise.reject(error);
      }
    );
  }
}

export default new API();
export { API };
