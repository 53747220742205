import API from "./api";
import AppFunctions from "../helpers/AppFunctions";
class AuthService {
  constructor() {}
  async login({ email, password }) {
    const response = await API.httpClient.post("/api/v1/auth/login", {
      email,
      password,
    });
    localStorage.setItem("authToken", JSON.stringify(response.data));
    return response.data;
  }
  async register({ firstname, lastname, email, password, rePassword, terms }) {
    const response = await API.httpClient.post("/api/v1/auth/register", {
      firstname,
      lastname,
      email,
      password,
      rePassword,
      terms,
    });
    return response.data;
  }
  async logout() {
    localStorage.removeItem("authToken");
  }

  async getPayloadUserLogged() {
    const auToken = localStorage.getItem("authToken");
    const payload  = AppFunctions.decodeJwt(auToken);
    if(payload){
      return payload;
    }
    return {};
  }
}

export default AuthService;
