const env = {
  app: {
    name: process.env.VUE_APP_NAME || "MeuApp", 
    version: process.env.VUE_APP_VERSION || "1.0.0",
  },
  config: {
    debug: process.env.VUE_APP_DEBUG === "true",
    locale: process.env.VUE_APP_LOCALE || "pt-BR",
  },
  api: {
    baseUrlAuth: process.env.VUE_APP_API_URL || "http://localhost:4000",
    baseUrlBusiness: process.env.VUE_APP_API_BUSINESS || 'http://localhost:3000',
    timeout: process.env.VUE_APP_API_TIMEOUT || 5000,
  },
};

export default env;