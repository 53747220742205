<template>
    <layout-sidebar>
        <div class="rn-activity-area rn-section-gapTop">
            <div class="container">
                <div class="mb--30">
                    <h3 class="title">Meu Financeiro</h3>
                </div>
                <div class="g-6 activity-direction mb--30">
                    <Table :columns="columns" :items="data" @row-click="handleRowClick" />
                </div>
            </div>
        </div>
    </layout-sidebar>
</template>

<script>
// import Layout from '@/components/layouts/Layout'
import LayoutSidebar from '@/components/layouts/LayoutSidebar';
import Table from '@/components/table/Table';
export default {
    name: 'CustomerPurchases',
    components: { LayoutSidebar, Table },
    data() {
        return {
            columns: [
                { key: 'date', label: 'Data' },
                { key: 'type', label: 'Tipo' },
                { key: 'grossValue', label: 'Valor Bruto' },
                { key: 'netValue', label: 'Valor Líquido' },
                { key: 'status', label: 'Status' },
                {
                    type: 'action',
                    label: '',
                    actions: [
                        { label: 'Detalhes', handler: (item) => this.editItem(item) },
                    ]
                }
            ],
            data: [
                {
                    date: '2024-12-15',
                    type: 'Entrada',
                    grossValue: 'R$ 500,00',
                    netValue: 'R$ 450,00',
                    status: 'Aprovado',
                    statusColor: 'green'
                },
                {
                    date: '2024-12-14',
                    type: 'Saída',
                    grossValue: 'R$ 300,00',
                    netValue: 'R$ 280,00',
                    status: 'Pendente',
                    statusColor: 'yellow'
                },
                {
                    date: '2024-12-13',
                    type: 'Entrada',
                    grossValue: 'R$ 1.000,00',
                    netValue: 'R$ 950,00',
                    status: 'Bloqueado',
                    statusColor: 'red'
                },
                {
                    date: '2024-12-12',
                    type: 'Saída',
                    grossValue: 'R$ 200,00',
                    netValue: 'R$ 190,00',
                    status: 'Processando',
                    statusColor: 'orange'
                },
                {
                    date: '2024-12-11',
                    type: 'Entrada',
                    grossValue: 'R$ 800,00',
                    netValue: 'R$ 750,00',
                    status: 'Aprovado',
                    statusColor: 'green'
                }
            ]
        };

    },
    methods: {
        handleRowClick(item) {
            console.log('Item clicado:', item);
        },
        editItem(item) {
            console.log('Editar:', item);
        },
        deleteItem(item) {
            console.log('Excluir:', item);
        }
    },
}
</script>