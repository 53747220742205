<template>
    <layout-sidebar>
        <div class="rn-activity-area rn-section-gapTop">
            <div class="container">
                <div class="mb--30">
                    <h3 class="title">Minhas Compras</h3>
                </div>
                <div class="g-6 activity-direction mb--30">
                    <Table :columns="columns" :items="data" @row-click="handleRowClick" />
                </div>
            </div>
        </div>
    </layout-sidebar>
</template>

<script>
// import Layout from '@/components/layouts/Layout'
import LayoutSidebar from '@/components/layouts/LayoutSidebar';
import Table from '@/components/table/Table';
export default {
    name: 'CustomerPurchases',
    components: { LayoutSidebar, Table },
    data() {
        return {
            columns: [
                { key: 'date', label: 'Data' },
                { key: 'product', label: 'Produto' },
                { key: 'value', label: 'Valor' },
                { key: 'status', label: 'Status' },
                { key: 'payment', label: 'Pagamento' },
                {
                    type: 'action',
                    label: 'Ações',
                    actions: [
                        { label: 'Baixar', handler: (item) => this.editItem(item) },
                        // { label: 'Excluir', handler: (item) => this.deleteItem(item) }
                    ]
                }
            ],
            data: [
                {
                    date: '2024-12-16',
                    product: 'Produto A',
                    value: 'R$ 100,00',
                    status: 'Pago',
                    statusColor: 'green', 
                    payment: 'Cartão de Crédito'
                },
                {
                    date: '2024-12-17',
                    product: 'Produto B',
                    value: 'R$ 200,00',
                    status: 'Em andamento',
                    statusColor: 'red',
                    payment: 'Boleto'
                }
            ]
        };

    },
    methods: {
        handleRowClick(item) {
            console.log('Item clicado:', item);
        },
        editItem(item) {
            console.log('Editar:', item);
        },
        deleteItem(item) {
            console.log('Excluir:', item);
        }
    },
}
</script>