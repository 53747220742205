<template>
    <div class="box-table table-responsive">
        <table class="table upcoming-projects">
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="`th-${index}`">
                        <span class="text-capitalize">{{ column.label }}</span>
                    </th>
                </tr>
            </thead>
            <!-- <tbody>
                <tr v-for="(item, index) in items" :key="`row-${index}`" :class="{ 'color-light': index % 2 === 0 }"
                    @click="onRowClick(item)">
                    <td v-for="(column, colIndex) in columns" :key="`td-${colIndex}`">
                        <span :class="column.class ? column.class : ''">
                            {{ column.accessor ? column.accessor(item) : item[column.key] }}
                        </span>
                    </td>
                </tr>
            </tbody> -->
            <tbody>
                <tr v-for="(item, index) in items" :key="`row-${index}`" :class="{ 'color-light': index % 2 === 0 }"
                    @click="onRowClick(item)">
                    <td v-for="(column, colIndex) in columns" :key="`td-${colIndex}`">
                        <span :class="column.class ? column.class : ''" :style="getCellStyle(column, item)">
                            <template v-if="column.type === 'action'">
                                <button v-for="action in column.actions" :key="action.label"
                                    @click.stop="action.handler(item)">
                                    {{ action.label }}
                                </button>
                            </template>
                            <template v-else>
                                {{ column.accessor ? column.accessor(item) : item[column.key] }}
                            </template>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "Table",
    props: {
        columns: {
            type: Array,
            required: true,
            // Estrutura esperada para as colunas: [{ key: 'nome_propriedade', label: 'Nome Coluna', class: 'opcional', accessor: (opcional) => ... }]
        },
        items: {
            type: Array,
            required: true,
            // Lista de objetos a serem exibidos na tabela.
        }
    },
    emits: ["row-click"], // Emite o evento ao clicar na linha
    methods: {
        onRowClick(item) {
            this.$emit("row-click", item);
        },
        getCellStyle(column, item) {
            const colorKey = `${column.key}Color`; // Adiciona o sufixo "Color"
            if (item[colorKey]) {
                return { color: item[colorKey] }; // Aplica a cor definida no item
            }
            return {};
            // if (column.key === 'status' && item.statusColor) {
            //     return { color: item.statusColor };
            // }
            // return {};
        }
    }
};
</script>