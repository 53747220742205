<template>
    <layout>
        <breadcrumb title="Moderna Login" current="Login" />

        <div class="login-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class=" offset-2 col-lg-4 col-md-6 ml_md--0 ml_sm--0 col-sm-12">
                        <div class="form-wrapper-one">
                            <logo/>
                            <br>
                            <h4>Login</h4>
                            <form @submit.prevent="login">
                                <div class="mb-5">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input v-model="modelLogin.email" type="email" id="exampleInputEmail1">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputPassword1" class="form-label">Password</label>
                                    <input v-model="modelLogin.password" type="password" id="exampleInputPassword1">
                                </div>
                                <div class="mb-5 rn-check-box">
                                    <input type="checkbox" class="rn-check-box-input" id="exampleCheck1">
                                    <label class="rn-check-box-label" for="exampleCheck1">Remember me leter</label>
                                </div>
                                <button type="submit" class="btn btn-primary mr--15">Entrar</button>
                                <router-link to="/sign-up" class="btn btn-primary-alta">Cadastrar</router-link>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Outras formas de login</h6>
                            <p>Em breve, você poderá fazer login usando plataformas como Google, Microsoft e outras.</p>
                            <button disabled class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button disabled class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button disabled class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button disabled class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/components/layouts/LayoutBlank";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import AuthService from "../service/auth"
import { toaster } from "../config/toast";
import Logo from "@/components/logo/Logo";

export default {
    name: 'Login',
    components: { Breadcrumb, Layout, Logo },
    data() {
        return {
            modelLogin: {
                email: "leonardoprado19@gmail.com",
                password: "23019"
            }
        }
    },
    methods: {
        async login() {
            try {
                const reponse = await new AuthService().login(this.modelLogin);
                console.log(reponse)
                if (reponse) {
                    this.$router.push("/")
                }
            } catch (error) {
                toaster("Erro ao entrar", "error")
            }
        }
    }
}
</script>