<template>
    <layout>
        <breadcrumb title="Sign Up" current="Sign Up" />

        <div class="login-area rn-section-gapTop">
            <div class="container">
                <div class="row g-5">
                    <div class="offset-2 col-lg-4 col-md-6 ml_md--0 ml_sm--0 col-sm-12">
                        <div class="form-wrapper-one registration-area">
                            <logo/>
                            <br>
                            <h4>Sign up</h4>
                            <form @submit.prevent="signUp">
                                <div class="mb-5">
                                    <label for="firstName" class="form-label">First name</label>
                                    <input v-model="modelSignUp.firstname" type="text" id="firstName">
                                </div>
                                <div class="mb-5">
                                    <label for="sastName" class="form-label">Last name</label>
                                    <input v-model="modelSignUp.lastname" type="text" id="sastName">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputEmail1" class="form-label">Email address</label>
                                    <input v-model="modelSignUp.email" type="email" id="exampleInputEmail1">
                                </div>
                                <div class="mb-5">
                                    <label for="newPassword" class="form-label">Create Password</label>
                                    <input v-model="modelSignUp.password" type="password" id="newPassword">
                                </div>
                                <div class="mb-5">
                                    <label for="exampleInputPassword1" class="form-label">Re Password</label>
                                    <input v-model="modelSignUp.rePassword" type="password" id="exampleInputPassword1">
                                </div>
                                <div class="mb-5 rn-check-box">
                                    <input v-model="modelSignUp.terms" type="checkbox" class="rn-check-box-input"
                                        id="exampleCheck1">
                                    <label class="rn-check-box-label" for="exampleCheck1">Allow to all tearms &
                                        condition</label>
                                </div>
                                <button type="submit" class="btn btn-primary mr--15">Cadastrar</button>
                                <router-link to="/login" class="btn btn-primary-alta">Entrar</router-link>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <div class="social-share-media form-wrapper-one">
                            <h6>Outras formas de cadastrar</h6>
                            <p>Em breve, você poderá se cadastrar usando plataformas como Google, Microsoft e outras.</p>

                            <button disabled  class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/google.png`)" alt="">
                                <span>Log in with Google</span>
                            </button>
                            <button disabled  class="another-login login-facebook">
                                <img class="small-image" :src="require(`@/assets/images/icons/facebook.png`)" alt="">
                                <span>Log in with Facebook</span>
                            </button>
                            <button disabled  class="another-login login-twitter">
                                <img class="small-image" :src="require(`@/assets/images/icons/tweeter.png`)" alt="">
                                <span>Log in with Twitter</span>
                            </button>
                            <button disabled  class="another-login login-linkedin">
                                <img class="small-image" :src="require(`@/assets/images/icons/linkedin.png`)" alt="">
                                <span>Log in with linkedin</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/components/layouts/LayoutBlank";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import AuthService from "../service/auth"
import { toaster } from "../config/toast";
import Logo from "@/components/logo/Logo";

export default {
    name: 'Signup',
    components: { Breadcrumb, Layout, Logo },
    data() {
        return {
            modelSignUp: {
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                rePassword: "",
                terms: false
            }
        }
    },
    methods: {
        async signUp() {
            try {
                const reponse = await new AuthService().register(this.modelSignUp);
                if (reponse) {
                    await this.$router.push("/login");
                    toaster("Conta criaca com sucesso!\nSó entrar!", "success")
                }
            } catch (error) {
                toaster("Erro ao criar conta!\nConfira todos campos ou contacte o suporte", "error")
            }
        }
    }
}

</script>