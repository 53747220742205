import { API } from "./api";

class UserService {
  api;
  constructor() {
    this.api = new API("business", true);
  }
  async get() {
    const response = await this.api.httpClient.get("/api/v1/user");
    return response.data;
  }
  async edit(data) {
    const response = await this.api.httpClient.put("/api/v1/user", data);
    return response.data;
  }
}

export default UserService;
