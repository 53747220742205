<template>
    <div class="loading" v-if="isLoading">
        <div class="section-title mb--30">
            <h3 class="title mb--0 live-bidding-title" data-sal-delay="150" data-sal-duration="800"> </h3>
        </div>
    </div>
</template>
<script>
import { useLoadingStore } from "../../stores/loadingStore"; // Importa o Pinia store
import { storeToRefs } from "pinia";
export default {

    setup() {
        const loadingStore = useLoadingStore();
        const { isLoading } = storeToRefs(loadingStore);
        return {
            isLoading
        }
    }
} 
</script>

<style scoped>
.loading {
    position: fixed;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(4, 4, 4, 0.253);
}
</style>