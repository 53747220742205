<template>
    <layout-sidebar>
        <div class="rn-activity-area rn-section-gapTop">
            <div class="container">
                <div class="mb--30">
                    <h3 class="title">Minha Carreira</h3>
                </div>
                <div class="g-6 activity-direction mb--30">
                    <Table :columns="columns" :items="data" @row-click="handleRowClick" />
                </div>
            </div>
        </div>
    </layout-sidebar>
</template>

<script>
// import Layout from '@/components/layouts/Layout'
import LayoutSidebar from '@/components/layouts/LayoutSidebar';
import Table from '@/components/table/Table';
export default {
    name: 'CustomerPurchases',
    components: { LayoutSidebar, Table },
    data() {
        return {
            columns: [
                { key: 'title', label: 'Título' },
                { key: 'shortDescription', label: 'Descrição Curta' },
                { key: 'progress', label: 'Progresso' },
                // { key: 'startDate', label: 'Data Início' },
                // { key: 'endDate', label: 'Data Fim' },
                // { key: 'completed', label: 'Concluído' },
                {
                    type: 'action',
                    label: 'Ações',
                    actions: [
                        { label: 'Visualizar', handler: (item) => this.viewItem(item) },
                        // { label: 'Excluir', handler: (item) => this.deleteItem(item) },
                    ]
                }
            ],
            data: [
                {
                    title: 'Curso de JavaScript',
                    shortDescription: 'Aprenda o básico e avançado de JavaScript.',
                    progress: "75%",
                    progressColor:"orange",
                    startDate: '2024-01-10',
                    endDate: '2024-02-10',
                    completed: false
                },
                {
                    title: 'Introdução ao Vue.js',
                    shortDescription: 'Curso para iniciantes no framework Vue.js.',
                    progress: "30%",
                    progressColor:"red",
                    startDate: '2024-03-01',
                    endDate: '2024-04-01',
                    completed: false
                },
                {
                    title: 'Desenvolvimento Backend com NestJS',
                    shortDescription: 'Curso sobre criação de APIs com NestJS.',
                    progress: "100%",
                    progressColor:"green",
                    startDate: '2023-11-01',
                    endDate: '2023-12-01',
                    completed: true
                },
                {
                    title: 'Banco de Dados MySQL',
                    shortDescription: 'Curso avançado de gerenciamento de dados.',
                    progress: "50%",
                    progressColor:"yellow",
                    startDate: '2024-02-15',
                    endDate: '2024-03-15',
                    completed: false
                }
            ]
        };

    },
    methods: {
        handleRowClick(item) {
            console.log('Item clicado:', item);
        },
        editItem(item) {
            console.log('Editar:', item);
        },
        deleteItem(item) {
            console.log('Excluir:', item);
        }
    },
}
</script>