// src/stores/loadingStore.js
import { defineStore } from 'pinia';

export const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: false, // Estado inicial do loading
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
  },
});
