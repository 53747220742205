import { createRouter, createWebHistory } from "vue-router";
import HomePageOne from "../views/home/HomePageOne";
import HomePageTwo from "../views/home/HomePageTwo";
import HomePageThree from "../views/home/HomePageThree";
import HomePageFour from "../views/home/HomePageFour";
import HomePageFive from "../views/home/HomePageFive";
import HomePageSix from "../views/home/HomePageSix";
import HomePageSeven from "../views/home/HomePageSeven";
import HomePageEight from "../views/home/HomePageEight";
import HomePageNine from "../views/home/HomePageNine";
import HomePageTen from "../views/home/HomePageTen";
import HomePageEleven from "../views/home/HomePageEleven";
import HomePageTwelve from "../views/home/HomePageTwelve";
import CreateProduct from "../views/CreateProduct";
import ProductDetails from "../views/ProductDetails";
import Connect from "../views/Connect";
import Collection from "../views/Collection";
import Creators from "../views/Creators";
import AuthorDetails from "../views/AuthorDetails";
import EditProfilePage from "../views/EditProfilePage";
import About from "../views/About";
import Contact from "../views/Contact";
import BlogDetails from "../views/blog/BlogDetails";
import BlogCategoryPosts from "../views/blog/BlogCategoryPosts";
import BlogTagPosts from "../views/blog/BlogTagPosts";
import BlogSingleColumn from "../views/blog/BlogSingleColumn";
import BlogTwoColumn from "../views/blog/BlogTwoColumn";
import BlogThreeColumn from "../views/blog/BlogThreeColumn";
import BlogFourColumn from "../views/blog/BlogFourColumn";
import ExploreOne from "../views/ExploreOne";
import ExploreTwo from "../views/ExploreTwo";
import ExploreThree from "../views/ExploreThree";
import ExploreFour from "../views/ExploreFour";
import ExploreFive from "../views/ExploreFive";
import ExploreSix from "../views/ExploreSix";
import ExploreSeven from "../views/ExploreSeven";
import ExploreEight from "../views/ExploreEight";
import ExploreNine from "../views/ExploreNine";
import ExploreTen from "../views/ExploreTen";
import ExploreEleven from "../views/ExploreEleven";
import ExploreTwelve from "../views/ExploreTwelve";
import ExploreThirteen from "../views/ExploreThirteen";
import ExploreFourteen from "../views/ExploreFourteen";
import UploadVariants from "../views/UploadVariants";
import Activity from "../views/Activity";
import UpcomingProjects from "../views/UpcomingProjects";
import Login from "../views/Login";
import Signup from "../views/Signup";
import ForgetPassword from "../views/ForgetPassword";
import PrivacyPolicy from "../views/PrivacyPolicy";
import Products from "../views/Products";
import Ranking from "../views/Ranking";
import NotFound from "../views/NotFound";
import Support from "../views/Support";
import TermsCondition from "../views/TermsCondition";
import ComingSoon from "../views/ComingSoon";
import Maintenance from "../views/Maintenance";
import CustomerPurchases from "../views/CustomerPurchases";
import MyCareer from "../views/MyCareer";
import MyFinancial from "../views/MyFinancial";

const routesProject = [
  {
    path: "/minhas-compras",
    name: "minhas-compras",
    component: CustomerPurchases,
    meta: {
      title: "Compras",
      requiresAuth: true,
    },
  },
  {
    path: "/minha-carreira",
    name: "minha-carreira",
    component: MyCareer,
    meta: {
      title: "Carreira",
      requiresAuth: true,
    },
  },
  {
    path: "/meu-financeiro",
    name: "meu-financeiro",
    component: MyFinancial,
    meta: {
      title: "Financeiro",
      requiresAuth: true,
    },
  },
];

const routes = [
  ...routesProject,
  {
    path: "/",
    name: "HomePage",
    component: HomePageSeven,
    meta: {
      title: "Moderna Pro",
      requiresAuth: true,
    },
  },
  {
    path: "/index-02",
    name: "HomePageTwo",
    component: HomePageTwo,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-03",
    name: "HomePageThree",
    component: HomePageThree,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-04",
    name: "HomePageFour",
    component: HomePageFour,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-05",
    name: "HomePageFive",
    component: HomePageFive,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-06",
    name: "HomePageSix",
    component: HomePageSix,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-07",
    name: "HomePageSeven",
    component: HomePageSeven,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-08",
    name: "HomePageEight",
    component: HomePageEight,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-09",
    name: "HomePageNine",
    component: HomePageNine,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-10",
    name: "HomePageTen",
    component: HomePageTen,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-11",
    name: "HomePageEleven",
    component: HomePageEleven,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/index-12",
    name: "HomePageTwelve",
    component: HomePageTwelve,
    meta: {
      title: "Moderna Pro",
    },
  },
  {
    path: "/create",
    name: "CreateProduct",
    component: CreateProduct,
    meta: {
      title: "Create || Moderna Pro",
    },
  },
  {
    path: "/product/:id",
    name: "ProductDetails",
    component: ProductDetails,
    meta: {
      title: "Product Details || Moderna Pro",
    },
  },
  {
    path: "/connect",
    name: "Connect",
    component: Connect,
    meta: {
      title: "Connect || Moderna Pro",
    },
  },
  {
    path: "/collection",
    name: "Collection",
    component: Collection,
    meta: {
      title: "Author || Moderna Pro",
    },
  },
  {
    path: "/creator",
    name: "Creators",
    component: Creators,
    meta: {
      title: "Collection || Moderna Pro",
    },
  },
  {
    path: "/author/:id",
    name: "AuthorDetails",
    component: AuthorDetails,
    meta: {
      title: "Author Details || Moderna Pro",
    },
  },
  {
    path: "/edit-profile",
    name: "EditProfilePage",
    component: EditProfilePage,
    meta: {
      title: "Edit Profile || Moderna Pro",
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "About || Moderna Pro",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact || Moderna Pro",
    },
  },
  {
    path: "/category/:slug",
    name: "BlogCategoryPosts",
    component: BlogCategoryPosts,
    meta: {
      title: "Blog Category List || Moderna Pro",
    },
  },
  {
    path: "/tag/:slug",
    name: "BlogTagPosts",
    component: BlogTagPosts,
    meta: {
      title: "Blog Tag List || Moderna Pro",
    },
  },
  {
    path: "/blog-details/:id",
    name: "BlogDetails",
    component: BlogDetails,
    meta: {
      title: "Blog Details || Moderna Pro",
    },
  },
  {
    path: "/blog-single-column",
    name: "BlogSingleColumn",
    component: BlogSingleColumn,
    meta: {
      title: "Blog Single Column || Moderna Pro",
    },
  },
  {
    path: "/blog-col-two",
    name: "BlogTwoColumn",
    component: BlogTwoColumn,
    meta: {
      title: "Blog Two Column || Moderna Pro",
    },
  },
  {
    path: "/blog-col-three",
    name: "BlogThreeColumn",
    component: BlogThreeColumn,
    meta: {
      title: "Blog Three Column || Moderna Pro",
    },
  },
  {
    path: "/blog",
    name: "BlogFourColumn",
    component: BlogFourColumn,
    meta: {
      title: "Blog Four Column || Moderna Pro",
    },
  },
  {
    path: "/explore-01",
    name: "ExploreOne",
    component: ExploreOne,
    meta: {
      title: "Explore Filter || Moderna Pro",
    },
  },
  {
    path: "/explore-02",
    name: "ExploreTwo",
    component: ExploreTwo,
    meta: {
      title: "Explore Isotop || Moderna Pro",
    },
  },
  {
    path: "/explore-03",
    name: "ExploreThree",
    component: ExploreThree,
    meta: {
      title: "Explore Carousel || Moderna Pro",
    },
  },
  {
    path: "/explore-04",
    name: "ExploreFour",
    component: ExploreFour,
    meta: {
      title: "Explore Simple || Moderna Pro",
    },
  },
  {
    path: "/explore-05",
    name: "ExploreFive",
    component: ExploreFive,
    meta: {
      title: "Explore With Place Bid || Moderna Pro",
    },
  },
  {
    path: "/explore-06",
    name: "ExploreSix",
    component: ExploreSix,
    meta: {
      title: "Place Bid With Filter || Moderna Pro",
    },
  },
  {
    path: "/explore-07",
    name: "ExploreSeven",
    component: ExploreSeven,
    meta: {
      title: "Place Bid With Carousel || Moderna Pro",
    },
  },
  {
    path: "/explore-08",
    name: "ExploreEight",
    component: ExploreEight,
    meta: {
      title: "Explore Carousel || Moderna Pro",
    },
  },
  {
    path: "/explore-09",
    name: "ExploreNine",
    component: ExploreNine,
    meta: {
      title: "Explore List Style || Moderna Pro",
    },
  },
  {
    path: "/explore-10",
    name: "ExploreTen",
    component: ExploreTen,
    meta: {
      title: "Explore List Column Two || Moderna Pro",
    },
  },
  {
    path: "/explore-11",
    name: "ExploreEleven",
    component: ExploreEleven,
    meta: {
      title: "Live Explore || Moderna Pro",
    },
  },
  {
    path: "/explore-12",
    name: "ExploreTwelve",
    component: ExploreTwelve,
    meta: {
      title: "Live Explore Carousel || Moderna Pro",
    },
  },
  {
    path: "/explore-13",
    name: "ExploreThirteen",
    component: ExploreThirteen,
    meta: {
      title: "Live With Place Bid || Moderna Pro",
    },
  },
  {
    path: "/explore-14",
    name: "ExploreFourteen",
    component: ExploreFourteen,
    meta: {
      title: "Explore Filter Left || Moderna Pro",
    },
  },
  {
    path: "/upload-variants",
    name: "UploadVariants",
    component: UploadVariants,
    meta: {
      title: "Upload Variants || Moderna Pro",
    },
  },
  {
    path: "/activity",
    name: "Activity",
    component: Activity,
    meta: {
      title: "Activity || Moderna Pro",
    },
  },
  {
    path: "/upcoming-projects",
    name: "UpcomingProjects",
    component: UpcomingProjects,
    meta: {
      title: "Upcoming Projects || Moderna Pro",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login || Moderna Pro",
    },
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: Signup,
    meta: {
      title: "Signup || Moderna Pro",
    },
  },
  {
    path: "/forget",
    name: "ForgetPassword",
    component: ForgetPassword,
    meta: {
      title: "Forget Password || Moderna Pro",
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Privacy Policy || Moderna Pro",
    },
  },
  {
    path: "/product",
    name: "Products",
    component: Products,
    meta: {
      title: "Product || Moderna Pro",
    },
  },
  {
    path: "/ranking",
    name: "Ranking",
    component: Ranking,
    meta: {
      title: "Ranking || Moderna Pro",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 || Moderna Pro",
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      title: "Ranking || Moderna Pro",
    },
  },
  {
    path: "/terms-condition",
    name: "TermsCondition",
    component: TermsCondition,
    meta: {
      title: "Terms & Condition || Moderna Pro",
    },
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: "Coming Soon || Moderna Pro",
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    meta: {
      title: "Maintenance || Moderna Pro",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isAuthenticated = () => {
  return !!localStorage.getItem("authToken"); // Simples; use Vuex ou outro método mais seguro para produção
};

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.requiresAuth && !isAuthenticated()) {
    next({ name: "Login" }); // Redireciona se não autenticado
  } else {
    next(); // Permite continuar para a rota
  }

  // Rola para o topo da página (executa sempre que o next() for chamado)
  window.scrollTo(0, 0);
});

export default router;
